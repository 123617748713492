var scrollTopY = 0;

$(document).ready(function() {
  onscrollHeadFollow();
  onClickNav();
  onClickSearchIcon();
});

/**
 * スクロールに応じたヘッダー固定切り替え
 */
function onscrollHeadFollow() {
  var $header = $('.js-fix-header');
  var headerH = $header.outerHeight();
  var overplus = $(window).outerWidth() * 0.1;
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > headerH + overplus) {
      // 固定ヘッダー時
      $header.css({ 'transition': 'transform .5s ease-out' });
      $header.addClass('standby').addClass('fixed');
    } else if ($(window).scrollTop() > headerH) {
      // 固定ヘッダー準備時
      if ($header.hasClass('standby')) {
        $header.css({ 'transition': 'transform .5s ease-out' });
      } else {
        $header.css({ 'transition': '' });
      }
      $header.addClass('standby').removeClass('fixed');
    } else {
      // 通常時
      $header.css({ 'transition': '' });
      $header.removeClass('standby fixed');
    }
  });
}

/**
 * SPハンバーガーメニュー押下時のイベントをセット
 */
function onClickNav() {
  var $toggleNav = $('.js-toggoleNav');
  var $header = $('.js-header');
  $toggleNav.on('click', function() {
    if ($header.hasClass('open')) {
      $header.removeClass('open');
      fixedBase('close');
    } else {
      $header.addClass('open');
      fixedBase('open');
    }
    
  });
}

/**
 * メニュー押下時に背景を固定する
 */
function fixedBase(toggle) {
  var $window = $(window);
  var $body = $('body');

  switch(toggle) {
    case 'open':
      // 現在のスクロール位置を保管
      scrollTopY = $window.scrollTop();
      // 下層コンテナの固定化
      $body.css({
        'position': 'fixed',
        'top': -1 * scrollTopY
      });
      break;
    case 'close':
      $body.attr({ style: '' });
      $window.scrollTop(scrollTopY);
      break;
  }
}

/**
 * PC検索アイコン押下時のイベントをセット
 */
function onClickSearchIcon() {
  var $searchIcon = $('.js-searchIcon');
  var $header = $('.js-header');
  $searchIcon.on('click', function() {
    if ($header.hasClass('search-mode')) {
      $header.removeClass('search-mode');
    } else {
      $header.addClass('search-mode');
      $(document).click(function(event) {
        if(!$(event.target).closest($header).length) {
          $header.removeClass('search-mode');
        }
      })
    }
  });
}